import React, { useState, useEffect } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import Footerlogo from "../assets/images/Footer-Logo.png";
import Trip1 from "../assets/images/Footerimg.png";
import Trip2 from "../assets/images/Trip2.png";
import Trip3 from "../assets/images/Trip3.png";
import Trip4 from "../assets/images/Trip4.png";
import Trip5 from "../assets/images/Trip5.png";
import { Link } from "react-router-dom";
import axios from "axios";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";
// Travel Links
const travelLinks = [
  { name: "Family Travel", link: "/family-travel/" },
  { name: "Group Travel", link: "/group-travel/" },
  { name: "All Inclusive Vacations", link: "/all-inclusive-vacations/" },
  { name: "Ocean Cruise", link: "/ocean-cruise/" },
  { name: "River Cruise", link: "/river-cruise/" },
  { name: "Anniversary", link: "/anniversary/" },
  { name: "Honeymoon Gateways", link: "/honeymoon/" },
  { name: "Destination Weddings", link: "/destinetion-wedding/" },
  { name: "Disney Vacations", link: "/disney-vacations/" },
];

// About Us Links
const aboutLinks = [
  { name: "Testimonials", link: "/testimonials" },
  { name: "Our Team", link: "/about-our-travel-agency/" },
  { name: "blog", link: "/blog/" },
  { name: "Terms Of Use", link: "/terms-of-use/" },
  { name: "Privacy Policy", link: "/privacy-policy/" },
];

// Help & FAQ Links
const faqLinks = [
  { name: "Customer Service", link: "/customer-service/" },
  { name: "Contact Us", link: "/go/" },
  { name: "Accessibility", link: "/accessibility/" },
];
const Footer: React.FC = () => {
  const [regions, setRegions] = useState<
    { regionId: string; regionTitle: string }[]
  >([]);
  const [selectedRegion, setSelectedRegion] = useState<string>("");

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.post(
          "https://demo.decordtech.com/tadmin/api/pages/regions",
          {},
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success) {
          const fetchedRegions = response.data.data.regions || [];
          setRegions(fetchedRegions);
          setSelectedRegion(fetchedRegions[0]?.regionId || "");
        }
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

    fetchRegions();
  }, []);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRegion(event.target.value);
  };

  return (
    <footer className="bg-black text-white py-6 px-2 md:py-12 md:px-4">
      <div className="container grid grid-cols-1 md:grid-cols-12 gap-8 md:px-20">
        {/* Logo and Intro Section */}
        <div className="col-span-12 md:col-span-3 space-y-4 font-akshar text-center md:text-left">
          <img
            src={Footerlogo}
            alt="Logo"
            className="h-16 md:h-20 lg:h-28 w-auto mx-auto md:mx-0"
          />
          <p className="text-xs font-anekbangla sm:text-sm opacity-70  text-white  lg:text-justify">
            Welcome To Travel N Relax, Your Premier Denver Travel Agency
            Specializing In Creating Fully Customized Vacations That Turn Your
            Travel Dreams Into Reality. With Over 20 Years Of Expertise.
          </p>
        </div>

        {/* Travel Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold font-mplus2 tracking-wide text-base md:text-lg uppercase text-white opacity-95 tracking-widest">
            Travel
          </h3>
          <ul className="text-xs sm:text-sm space-y-1">
            {travelLinks.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  className="font-akshar text-white opacity-70 tracking-widest"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* About Us Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold font-mplus2 text-base md:text-lg uppercase text-white opacity-95 tracking-wide">
            About Us
          </h3>
          <ul className="text-xs font-anekbangla sm:text-sm space-y-1 text-white opacity-70 tracking-widest">
            {aboutLinks.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Help & FAQ Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold tracking-wide-mplus2 text-base md:text-lg uppercase text-white opacity-95 tracking-wide">
            Help & FAQ's
          </h3>
          <ul className="text-xs sm:text-sm space-y-1 font-akshar text-white opacity-70 tracking-widest">
            {faqLinks.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
          <div className="space-y-2 mt-4 md:mt-10">
            <h3 className="font-semibold tracking-wide-mplus2 text-base md:text-lg uppercase text-white opacity-95 tracking-widest">
              Contact Us
            </h3>
            <p className="text-xs sm:text-sm font-akshar mt-3 text-white opacity-70">
              Phone: <a href="tel:303-317-6945">303-317-6945</a>
            </p>
            <p className="text-xs sm:text-sm font-akshar text-white opacity-70">
              Email:{" "}
              <a href="mailto:info@Travelnrelax.com">info@Travelnrelax.com</a>
            </p>
          </div>
        </div>

        {/* Recent Trips Section */}
        <div className="col-span-12 md:col-span-3 text-center md:text-left">
          <h3 className="font-semibold tracking-wide-mplus2 text-base md:text-lg uppercase text-white opacity-90 tracking-wide">
            Recent Trips
          </h3>
          <div className="grid grid-cols-3 gap-3 mt-5">
            {[Trip1, Trip2, Trip3, Trip4, Trip5, Trip2].map((trip, index) => (
              <img
                key={index}
                src={trip}
                alt={`Trip ${index + 1}`}
                className="h-16 sm:h-20 w-full object-fill"
              />
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto w-full md:w-[90%] mt-6 md:mt-12 border-t-2 border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <div className="flex items-center space-x-2 text-xs sm:text-sm">
          <label
            htmlFor="region"
            className="text-white font-akshar opacity-70 tracking-widest"
          >
            Choose Region:
          </label>
          <select
            id="region"
            className="bg-black  border border-gray-400 py-2 px-4 text-white"
            value={selectedRegion}
            onChange={handleRegionChange}
          >
            {regions.map((region) => (
              <option
                key={region.regionId}
                value={region.regionId}
                className="font-akshar opacity-70 tracking-widest"
              >
                {region.regionTitle}
              </option>
            ))}
          </select>
        </div>

        <div className="flex space-x-4">
          <a
            href="http://facebook.com/TravelnRelax"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500"
          >
            <FaFacebookF className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
          <a
            href="http://twitter.com/travelnrelax"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-400"
          >
            <FaTwitter className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
          <a
            href="https://www.linkedin.com/company/travel-n-relax/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-700"
          >
            <FaLinkedinIn className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
          <a
            href="https://www.instagram.com/travelnrelax"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-pink-500"
          >
            <FaInstagram className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
          <a
            href="https://www.youtube.com/@travelnrelax621"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-red-600"
          >
            <FaYoutube className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
          <a
            href="https://www.yelp.com/biz/travel-n-relax-aurora-2"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-green-500"
          >
            <IoIosGlobe className="h-5 sm:h-6 w-5 sm:w-6" />
          </a>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="flex flex-col items-center text-center mt-8 md:mt-12 w-full">
        <div className="opacity-70 text-xs sm:text-sm tracking-widest font-akshar">
          Copyright©2024TRAVEL’N RELAX USA, Inc. All rights reserved.
        </div>
        <div className="opacity-70 text-xs sm:text-sm tracking-widest font-akshar flex space-x-4">
          <Link to="/privacy-policy/">Privacy Policy</Link>
          <Link to="/terms-of-use/">Terms & Conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
