import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MonthlyDestinationCard from "./MonthlyDestinationCard"; // Import the Card Component

interface Destination {
  destinationId: string;
  bannerTitle: string;
  countryName: string;
  destinationDescription: string;
  bannerImage: string;
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const MonthlyDestinationList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [destinations, setDestinations] = useState<Destination[]>([]);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const baseUrl =
          "https://demo.decordtech.com/tadmin/api/pages/monthPageDetails";
        const response = await axios.post(
          baseUrl,
          { monthId: id },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.viewMoreDestinations) {
          setDestinations(response.data.data.viewMoreDestinations);
        }
      } catch (err) {
        console.error("Error fetching destination details:", err);
      }
    };

    if (id) fetchDestinations();
  }, [id]);

  if (destinations.length === 0) return null;

  return (
    <div className="px-4 md:px-16 lg:px-24 py-8">
      <h2 className="text-center text-2xl md:text-3xl font-bold tracking-wide mb-6 uppercase">
        More Destinations
      </h2>

      {/* Grid to Display Monthly Destination Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 justify-items-center w-full max-w-6xl mx-auto">
        {destinations.map((destination) => (
          <MonthlyDestinationCard
            key={destination.destinationId}
            title={destination.bannerTitle || destination.countryName}
            description={destination.destinationDescription}
            imageUrl={destination.bannerImage}
            destinationId={destination.destinationId}
          />
        ))}
      </div>
    </div>
  );
};

export default MonthlyDestinationList;
