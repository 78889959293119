import React, { useState, useEffect } from "react";
import Right from "../assets/images/Right-TravelMonth.svg";
import Left from "../assets/images/Left-TravelMonth.svg";
import axios from "axios";
import { useParams } from "react-router-dom";

interface OverviewData {
  bs_btn_link: string;
  bs_btn_text: string;
  bs_desc: string;
  bs_title: string;
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const TravelMonthSection: React.FC = () => {
  const { id } = useParams<{ id: string }>();  // Get the slug from the URL
  const [introduction, setIntroduction] = useState<OverviewData | null>(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const baseUrl = "https://demo.decordtech.com/tadmin/api/";
        const response = await axios.post(
          `${baseUrl}/pages/monthPageDetails`,
          { monthId: id },  // Use slugified month title as monthId
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (
          response.data.success &&
          response.data.data.introduction.length > 0
        ) {
          const introData = response.data.data.introduction[0];

          // Map API response to match OverviewData interface
          setIntroduction({
            bs_title: introData.title,
            bs_desc: introData.description,
            bs_btn_text: introData.buttonText,
            bs_btn_link: introData.buttonLink,
          });
        }
      } catch (err) {
        console.error("Error fetching overview data:", err);
      }
    };

    fetchOverviewData();
  }, [id]);

  return (
    introduction && (
      <div className="relative">
        <div className="py-16 px-4 md:px-10 lg:px-20 text-center bg-white">
          <h2 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar text-center uppercase">
            {introduction.bs_title}  {/* This will display the title */}
          </h2>
          <p className="lg:text-base sm:tracking-wide font-poppins mt-3 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
            {introduction.bs_desc}
          </p>
          <button
            className="border border-black text-black px-6 py-2 mt-4 font-semibold hover:bg-gray-200 transition duration-300 ease-in-out"
            onClick={() => window.open(introduction.bs_btn_link, "_blank")}
          >
            {introduction.bs_btn_text}
          </button>
        </div>
        <div className="absolute top-0 right-0">
          <img src={Right} alt="Right Decoration" className="object-fill" />
        </div>
        <div className="absolute bottom-0 left-0">
          <img
            src={Left}
            alt="Left Decoration"
            className="h-80 w-full object-fill"
          />
        </div>
      </div>
    )
  );
};

export default TravelMonthSection;
